import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            {/* <ResumeDownload /> */}
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>TourNate</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://tournate.com/'}
                        >
                            <h4>tournate.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Backend Developer</h3>
                        <b>
                            <p>Summer 2023 - Still Going</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                Tournate is a fast growing travel solutions company founded in 2010 in Turkey.
                Tournate offers today's most advanced technology solutions to Tour Operators, Travel Agencies and Hotels in the fastest and most reliable way.
                All of solutions consist of services for producing a wide variety of travel solutions, performance and revenue enhancement using cloud-based resources.
                Tournate currently works with more than 50 Tour Operators and Travel professionals in 17 countries.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            A pool system was developed for the tickets of some airline companies.
                        </p>
                    </li>
                    <li>
                        <p>
                            Various screen improvements were made on the back office.
                        </p>
                    </li>
                    <li>
                        <p>
                            Speed improvements were implemented on the screens to enhance user experience.
                        </p>
                    </li>
                    <li>
                        <p>
                            Detailed reports were created for logistics companies.
                        </p>
                    </li>
                    <li>
                        <p>
                            Special reports were developed in response to the requests of many tour operators.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
