import React, { useState } from 'react';
import DosPlayer from '../dos/DosPlayer';
import Window from '../os/Window';
import useInitialWindowSize from '../../hooks/useInitialWindowSize';

export interface ChessApp extends WindowAppProps {}

const ChessApp: React.FC<ChessApp> = (props) => {
    const [width, setWidth] = useState(980);
    const [height, setHeight] = useState(670);
    const { initWidth, initHeight } = useInitialWindowSize({ margin: 10 });


    return (
        <Window
            top={10}
            left={10}
            width={initWidth}
            height={initHeight}
            windowTitle="Chess"
            windowBarColor="#1C1C1C"
            windowBarIcon="windowGameIcon"
            bottomLeftText={'Powered by JSDOS & Enes'}
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
            onWidthChange={setWidth}
            onHeightChange={setHeight}
        >
            <DosPlayer width={width} height={height} bundleUrl="chess.jsdos" />
        </Window>
    );
};

export default ChessApp;
