import React from 'react';
import robot from '../../assets/pictures/robotcompetition.jpg';
import dotnetDevelopment from '../../assets/pictures/dotnet-development.png';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Hasan Enes</h3>
            <br />
            <div className="text-block">
                <p>
                I'm a software developer currently working at Tournate! In June
                    In 2024, I graduated from Ismet Inonu Vocational High School
                    With my diploma in software development.
                </p>
                <br />
                <p>
                    Thank you for taking the time to check out my portfolio. I
                    really hope you enjoy exploring it as much as I enjoyed
                    building it. If you have any questions or comments, feel
                    free to contact me using{' '}
                    <Link to="/contact">this form</Link> or shoot me an email at{' '}
                    <a href="mailto:mailenes260@gmail.com">
                        mailenes260@gmail.com
                    </a>
                </p>
            </div>
            {/* <ResumeDownload /> */}
            <div className="text-block">
                <h3>About Me</h3>
                <br />
                <p>
                    From an early age I had a curiosity about how things were.
                    worked. This naturally made me completely obsessed
                    I met Arduino and fell in love with building things.
                    In high school, I participated in the International Robot competition.
                    This was my first real experience
                    in programming.  
                </p>
                <br />
                <div className="captioned-image">
                    <img src={robot} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> A photo taken at the competition site
                        </sub>
                    </p>
                </div>

                
                <br />
                <p>
                    In 2023, I completed a one-year mandatory internship at Tournate.
                    During this period, I gained in-depth knowledge and experience, particularly in the Backend field.
                    The skills I acquired during my internship significantly enhanced my technical capabilities.
                    I am currently continuing my career at the same company as a Backend Developer.
                </p>
                <br />
                
                <div style={styles.verticalImage}>
                        <img src={dotnetDevelopment} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> .Net Development
                            </sub>
                        </p>
                    </div>
                {/* <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>My Hobbies</h3>
                        <br />
                        <p>
                            Beyond software, I have a lot of hobbies that I
                            enjoy doing in my free time. The more tangible
                            hobbies I have are{' '}
                            <Link to="/projects/music">Music Production</Link>{' '}
                            and creating{' '}
                            <Link to="/projects/art">Digital Art</Link>. You can
                            read more about each of these on their respective
                            pages under my projects tab. Some other hobbies I
                            enjoy are working out, cooking, and (unsurprisingly)
                            playing video games.
                        </p>
                        <br />
                        <p>
                            In college, I was an active member in the fraternity
                            Sigma Alpha Epsilon and held multiple positions in
                            the chapter. I met a lot of amazing people through
                            my fraternity and thoroughly enjoyed the community.
                        </p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={meNow} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> Me, April 2022
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    Thanks for reading about me! I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer. If you find the easter egg make sure to let me know
                    on twitter{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/henryheffernan"
                    >
                        @henryheffernan
                    </a>{' '}
                    Good luck and have fun!
                </p>
                <br />
                <p>
                    If you have any questions or comments I would love to hear
                    them. You can reach me through the{' '}
                    <Link to="/contact">contact page</Link> or shoot me an email
                    at{' '}
                    <a href="mailto:mailenes260@gmail.com">
                        mailenes260@gmail.com
                    </a>
                </p> */}
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
