import React from 'react';
import DosPlayer from '../dos/DosPlayer';
import Window from '../os/Window';
// @ts-ignore
import monkey from '../../assets/pictures/monkey.mp4';
import VideoAsset from '../general/VideoAsset';

const EasterEgg = () => {
    return (
        <>
            <VideoAsset src={monkey} />
        </>
    );
};

export default EasterEgg;