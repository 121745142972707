import React from 'react';
// @ts-ignore
import saga from '../../../assets/pictures/projects/software/saga.mp4';
// @ts-ignore
import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
import scroll from '../../../assets/pictures/projects/software/scroll.mp4';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';
import MenuPlus from '../../../assets/pictures/projects/software/menuplus.png';
import website from '../../../assets/pictures/projects/software/desktop.png';
export interface SoftwareProjectsProps {}

const SoftwareProjects: React.FC<SoftwareProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Software</h1>
            <h3>Projects</h3>
            <br />
            <p>
                Below are some of my favorite software projects I have worked on
                over the last few years.
            </p>
            <br />
            {/* <ResumeDownload /> */}
            <br />
            <div className="text-block">
                <h2>Enes.Studio</h2>
                <br />
                <p>
                    enes.studio is my portfolio website and also the website you are on right now.
                    This project was a lot of fun to do and challenged me both technically and creatively.
                </p>
                <br />
                <div className="captioned-image">
                <img src={website} style={styles.image} alt="" />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> A picture from the first version of the site.
                        </sub>
                    </p>
                </div>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://enes.studio"
                        >
                            <p>
                                <b>[This Site]</b> - enes.studio
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/Eneswunbeaten/Win-Portfolio"
                        >
                            <p>
                                <b>[GitHub]</b> - Site Repository
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Menu Plus</h2>
                <br />
                <p>
                    Menu Plus was an innovative solution we developed for restaurants.
                    This project offered a platform that allowed you to integrate your menus 
                    into your restaurant's website and manage orders online. However, due to our recent workload,
                    we were unable to allocate sufficient time to the project and, as a result, had to leave it unfinished.
                    The back-end of the project was developed by me, while the front-end was developed by <a href="https://mehmet.studio">
                        Mehmet
                    </a>.
                </p>
                <br />
                <div className="captioned-image">
                <img src={MenuPlus} style={styles.image} alt="" />
                    <div style={styles.caption}>
                        <p>
                            <sub>
                                <b>Figure 2: </b> A frame from the demo restaurant we created
                                 with fake data while the site was running
                            </sub>
                        </p>
                    </div>
                </div>
                <p>
                    We are withholding the source code of this project because
                     we have plans to develop and commercialize it in the future.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://menuplus.com.tr"
                        >
                            <p>
                                <b>[Website]</b> - MenuPlus Preview
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    Unfortunately, the project is no longer fully operational as 
                    it is too costly to maintain the back end of the project.
                </p>
            </div>
            {/* <div className="text-block">
                <h2>Skip the Scroll</h2>
                <br />
                <p>
                    While working at Hover, I spent a lot of time on GitHub
                    issue pages looking for answers and solutions to problems I
                    had run into. I would always find myself sifting though the
                    comments trying to find the right answer, which usually had
                    the most upvotes and positive reactions. With that
                    information, I decided to create a very simple chrome
                    extension that would sift through all the comments on the
                    page, sort them by positive reactions, and then allow you to
                    traverse them from most positive reactions to least positive
                    reactions.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={scroll} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> Skip the Scroll in action, finding
                            the highest rated comments and scrolling right to
                            them
                        </sub>
                    </p>
                </div>
                <p>
                    The extension is open source and currently released on the
                    Chrome web store. Skip the Scroll is obviously not a project
                    with massive scope, but was fun to make and dive into the
                    world of browser extensions. I wanted to showcase since it's
                    a developer tool and I wanna give it some visibility for
                    those who might find it useful.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://github.com/henryjeff/skip-the-scroll"
                        >
                            <p>
                                <b>[GitHub]</b> - Skip the Scroll Repository
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://chrome.google.com/webstore/detail/skip-the-scroll/mfehannpjmgfagldoilpngeoecdfgmnd"
                        >
                            <p>
                                <b>[Chrome Web Store]</b> - Skip the Scroll
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    If you are a developer and have also found yourself
                    scrolling through github comment after github comment saying
                    "i also have this problem...", then I highly recommend you
                    check out Skip the Scroll to save you some of your precious
                    time. If you like it, feel free to star it on GitHub and
                    rate it on the Chrome web store.
                </p>
            </div> */}
            {/* <ResumeDownload /> */}
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default SoftwareProjects;
